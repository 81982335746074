/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #366d9c;
  --ion-color-primary-rgb: 54, 109, 156;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #306089;
  --ion-color-primary-tint: #4a7ca6;

  --ion-color-secondary: #f17a48;
  --ion-color-secondary-rgb: 241, 122, 72;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #d46b3f;
  --ion-color-secondary-tint: #f2875a;

  --ion-color-tertiary: #2fa377;
  --ion-color-tertiary-rgb: 47, 163, 119;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #298f69;
  --ion-color-tertiary-tint: #44ac85;

  --ion-color-success: #2fa377;
  --ion-color-success-rgb: 47, 163, 119;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #298f69;
  --ion-color-success-tint: #44ac85;

  --ion-color-warning: #efa948;
  --ion-color-warning-rgb: 239, 169, 72;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #d2953f;
  --ion-color-warning-tint: #f1b25a;

  --ion-color-danger: #ef4949;
  --ion-color-danger-rgb: 239, 73, 73;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d24040;
  --ion-color-danger-tint: #f15b5b;

	--ion-color-dark: #0c0f18;
	--ion-color-dark-rgb: 12,15,24;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255,255,255;
	--ion-color-dark-shade: #0b0d15;
	--ion-color-dark-tint: #24272f;

  --ion-color-medium: #78746d;
  --ion-color-medium-rgb: 120, 116, 109;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #6a6660;
  --ion-color-medium-tint: #86827c;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

/* @media (prefers-color-scheme: dark) { */


  body.dark {
    --ion-color-primary: #366d9c;
    --ion-color-primary-rgb: 54, 109, 156;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #306089;
    --ion-color-primary-tint: #4a7ca6;

    --ion-color-secondary: #f17a48;
    --ion-color-secondary-rgb: 241, 122, 72;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #d46b3f;
    --ion-color-secondary-tint: #f2875a;

    --ion-color-tertiary: #2fa377;
    --ion-color-tertiary-rgb: 47, 163, 119;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #298f69;
    --ion-color-tertiary-tint: #44ac85;

    --ion-color-success: #2fa377;
    --ion-color-success-rgb: 47, 163, 119;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #298f69;
    --ion-color-success-tint: #44ac85;

    --ion-color-warning: #efa948;
    --ion-color-warning-rgb: 239, 169, 72;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #d2953f;
    --ion-color-warning-tint: #f1b25a;

    --ion-color-danger: #ef4949;
    --ion-color-danger-rgb: 239, 73, 73;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #d24040;
    --ion-color-danger-tint: #f15b5b;

    --ion-color-light: #0c0f18;
    --ion-color-light-rgb: 12,15,24;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #0b0d15;
    --ion-color-light-tint: #24272f;

    --ion-color-medium: #78746d;
    --ion-color-medium-rgb: 120, 116, 109;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #6a6660;
    --ion-color-medium-tint: #86827c;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body.dark {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios body.dark ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body.dark {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
/* } */

:root {
  --measure: 70ch;
  --ratio: 1.5;
  --s-5: calc(var(--s-4) / var(--ratio));
  --s-4: calc(var(--s-3) / var(--ratio));
  --s-3: calc(var(--s-2) / var(--ratio));
  --s-2: calc(var(--s-1) / var(--ratio));
  --s-1: calc(var(--s0) / var(--ratio));
  --s0: 1em;
  --s1: calc(var(--s0) * var(--ratio));
  --s2: calc(var(--s1) * var(--ratio));
  --s3: calc(var(--s2) * var(--ratio));
  --s4: calc(var(--s3) * var(--ratio));
  --s5: calc(var(--s4) * var(--ratio));
  --border-thin: 1px;
  --border-thicc: 0.2em;
}
