ion-tab-bar {
  box-sizing: border-box;
  width: clamp(5em, calc(100% - 2em), 70em);
  position: absolute;
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5em;
  border-radius: 1em;
  justify-content: space-evenly;
  gap: 0.5em;
  background: rgba(255, 255, 255, 0.304);
  /* box-shadow: 0 8px 12px 0 rgba(31, 38, 135, 0.186); */
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;

  backdrop-filter: blur(5px);
  border: 1px solid rgba(0, 0, 0, 0.13);
}

ion-tab-button {
  border-radius: 1em;
  border: 1px solid rgba(255, 255, 255, 0.603);
  box-shadow: 0 4px 8px 0 rgba(31, 38, 135, 0.27);

  background-image: linear-gradient(
    45deg,
    hsl(0, 0%, 97.3%),
    hsl(0, 0%, 90.9%),
    hsl(0, 0%, 97.3%),
    hsl(0, 0%, 90.9%)
  );
  color: var(--ion-color-medium);
  --color-focused: var(--ion-color-secondary);
  --color-selected: var(--ion-color-primary);
}

ion-tab-button:hover svg{
  animation-name: wobble;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);

}

ion-tab-button.tab-selected {
  color: var(--ion-color-tertiary);
}
@keyframes wobble {
  from {
    transform: translate3d(0, 0, 0);
  }

  15% {
    transform: translate3d(-0.20em, 0, 0) rotate3d(0, 0, 1, -4deg);
  }

  30% {
    transform: translate3d(0.15em, 0, 0) rotate3d(0, 0, 1, 3deg);
  }

  45% {
    transform: translate3d(-0.10em, 0, 0) rotate3d(0, 0, 1, -2deg);
  }

  60% {
    transform: translate3d(0.05em, 0, 0) rotate3d(0, 0, 1, 2deg);
  }

  75% {
    transform: translate3d(-0.025em, 0, 0) rotate3d(0, 0, 1, -1deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

